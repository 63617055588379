@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://unpkg.com/ionicons@4.4.2/dist/css/ionicons.min.css);
html body {
  background-image: linear-gradient(to right, #054779, #00a495, #054779);
  font-family: "Poppins", sans-serif;
  font-weight: 300;
}
.home-fluid {
  background: #fff;
  margin-top: 20%;
  padding: 50px;
  border-radius: 2px;
  box-shadow: 0 0 5px #111;
}
.home-fluid .home-logo {
  width: 100%;
  height: 155px;
  object-fit: contain;
  margin-bottom: 10px;
}
.home-fluid .home-link {
  width: 100%;
  background: #113253;
  color: #fff;
  text-align: center;
  display: block;
  line-height: 45px;
  font-size: 25px;
  font-weight: 600;
  text-transform: uppercase;
  padding: 8px 5px;
  border-radius: 2px;
  cursor: pointer;
  text-decoration: none;
  border: none;
}

header {
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 10000;
}
.header-mask {
  height: 95px;
  background: inherit;
  width: 100%;
  float: left;
}
.top-head {
  background: #fff;
  padding: 10px 0;
  box-shadow: 0 0 5px #111;
  border-radius: 0 0 15px 15px;
}
.top-head .top-head-logo {
  height: 65px;
  padding-left: 15px;
  max-width: 100%;
  object-fit: contain;
}
.top-head .top-head-link {
  height: 65px;
  display: block;
  float: right;
  padding-right: 15px;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}
.top-head .top-head-link div {
  background: #113253;
  height: 65px;
  border-radius: 10px;
}
.top-head .top-head-link div i {
  line-height: 65px;
  font-size: 40px;
  width: 60px;
  display: block;
  float: left;
  text-align: center;
}
.top-head .top-head-link div span {
  padding: 14px 15px 14px 10px;
  line-height: 18px;
  float: left;
  font-size: 14px;
  font-weight: 500;
}
.footer-tel {
  background: #47d1bc;
  width: 50px;
  height: 50px;
  display: block;
  color: #fff;
  text-align: center;
  line-height: 50px;
  font-size: 31px;
  border-radius: 50%;
  position: fixed;
  right: 15px;
  bottom: 15px;
}
.category-menu {
  height: 120px;
  margin: 15px 0px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 0 5px #111;
  padding-top: 10px;
  padding-bottom: 10px;
}
.category-items {
  width: 100%;
  background: #f1f1f1;
  border-radius: 7px;
  cursor: pointer;
}
.category-items.active {
  border-bottom: 5px solid #113253;
  background: #ccc;
}
.category-items img {
  width: 100%;
  height: 70px;
  text-align: center;
  object-fit: contain;
  display: block;
}
.category-items label {
  margin: 0;
  line-height: 30px;
  text-align: center;
  width: 100%;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;
  color: #113253;
}
.sale-items {
  height: 75px;
  margin: 3px 0px;
  background: #f1f1f1;
  border-radius: 8px;
  box-shadow: 0 0 5px #111;
  padding-top: 5px;
  padding-bottom: 5px;
}
.sale-items .items label {
  margin: 0;
  line-height: 26px;
  font-size: 14px;
  font-weight: 600;
  color: #113253;
  cursor: pointer;
}
.sale-items .items img {
  height: 65px;
  width: 80px;
  border: 2px solid #ccc;
  border-radius: 10px;
  object-fit: contain;
  float: left;
  margin-right: 15px;
  cursor: pointer;
}
.sale-items .items h5 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0;
  line-height: 26px;
  color: #113253;
  font-size: 16px;
  cursor: pointer;
}
.sale-items .items p {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0;
  line-height: 12px;
  color: #113253;
  font-size: 10px;
  cursor: pointer;
}
.sale-items .items label::after {
  content: "BD";
  margin-left: 3px;
}
.sale-items .items {
  float: left;
  width: calc(100% - 80px);
}
.add-to-cart,
.remove-from-cart {
  width: 75px;
  float: right;
  height: 65px;
  text-align: center;
}
.add-to-cart i {
  line-height: 65px;
  width: 65px;
  height: 65px;
  display: block;
  margin: auto;
  border: none;
  background: #eae1e2;
  font-size: 40px;
  color: #113253;
  border-radius: 50%;
  cursor: pointer;
  font-weight: 700;
  font-style: normal;
}
footer {
  height: 75px;
}
.add-to-cart i:hover {
  color: #28486a;
  background: #e8e7e7;
}
.add-to-cart i:active {
  color: #0a1c2f;
  background: #cac9c9;
  width: 55px;
  height: 55px;
  line-height: 55px;
  margin-top: 5px;
}
.menu-item {
  padding: 0 40px;
  margin: 5px 10px;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  cursor: pointer;
  border: none;
}
.menu-item-wrapper {
  width: 200px;
  padding-left: 15px;
  padding-right: 15px;
  border: none;
}
.menu-item-wrapper:active,
.menu-item-wrapper:focus {
  border: none;
}
.category-menu {
  margin-left: -15px;
  margin-right: -15px;
  padding-left: 15px;
  padding-right: 15px;
}
.whatsapp-share {
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
}
.whatsapp-share-link,
.whatsapp-share-link:hover {
  display: block;
  height: 65px;
  padding: 10px;
  width: 320px;
  background: #01E675;
  text-align: left;
  border: none;
  border-radius: 15px 15px 0 0;
  margin: auto;
  color: #fff;
  cursor: pointer;
}
.whatsapp-share-link2, .whatsapp-share-link2:hover{
  background: #113253;
}
.whatsapp-share-link i {
  cursor: pointer;
  width: 45px;
  display: inline-block;
  height: 45px;
  float: left;
  font-size: 35px;
  text-align: center;
}
.whatsapp-share-link label {
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
  line-height: 20px;
  margin-top: 2px;
  cursor: pointer;
}
.cart-clear {
  height: 65px;
  float: left;
  background: #d76e6e;
  color: #fff;
  line-height: 35px;
  padding: 15px 12px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 15px;
  font-weight: 600;
}
.cart-clear i {
  width: 35px;
  height: 35px;
  float: left;
  background: #c42323;
  border-radius: 50%;
  font-size: 35px;
  color: #d76e6e;
  text-align: center;
  margin-right: 5px;
}
.cart-total {
  float: right;
  background: #00e676;
  padding: 8px 12px;
  text-align: right;
  font-size: 15px;
  line-height: 25px;
  font-weight: 600;
  color: #fff;
  border-radius: 8px;
  height: 65px;
}
.cart-total strong {
  font-weight: 700;
  font-size: 22px;
}
.remove-from-cart i {
  line-height: 65px;
  width: 65px;
  height: 65px;
  display: block;
  margin: auto;
  border: none;
  background: #e3a0a8;
  font-size: 40px;
  color: #9d4343;
  border-radius: 50%;
  cursor: pointer;
  font-weight: 700;
  font-style: normal;
}
.sale-items-final {
  width: 100%;
}
.sale-items-support {
  height: 55px;
  clear: both;
  float: left;
  margin-top: 5px;
  border-radius: 8px;
  width: 100%;
}
.sale-items-support button,
.sale-items-support button:hover {
  height: 55px;
  width: 55px;
  float: left;
  font-size: 38px;
  font-weight: 700;
  color: #fff;
  border: none;
  border-radius: 0px;
  border-radius: 0 15px 15px 0;
  background: #89cead;
}
.sale-items-support button:first-child,
.sale-items-support button:first-child:hover {
  border-radius: 15px 0 0 15px;
  background: #e3a0a8;
}
.sale-items-support input {
  height: 55px;
  width: 75px;
  float: left;
  border: 2px solid #d1d1d1;
  border-radius: 0;
  padding: 0;
  text-align: center;
  font-size: 25px;
  font-weight: 600;
  color: #999;
}
.sale-items-support textarea {
  height: 55px;
  width: calc(100% - 187px);
  float: right;
  border: 2px solid #d1d1d1;
  border-radius: 15px;
  padding: 4px 10px;
  max-height: 55px;
  color: #999;
  font-size: 13px;
}
.unset-height {
  height: unset;
}
.no-after::after {
  display: none;
  content: "";
}
.token-fluid{
  width: 100%;
}
.token-fluid .tables, .home-fluid .tables{
  width: 100%;
  padding: 20px 0 40px 0;
}
.token-fluid .tables label, .home-fluid .tables label{
  width: 160px;
  background: #E6AD2A;
  height: 160px;
  display: block;
  margin: auto;
  font-size: 70px;
  line-height: 160px;
  text-align: center;
  color: #113253;
  border-radius: 50%;
  font-weight: 700;
  text-transform: capitalize;
}
.token-fluid .tables label.take-away, .home-fluid .tables label.take-away{
  line-height: 40px;
  font-size: 40px;
  padding-top: 35px;
}
.bg-10{background: #828282;}
.no-border{border: none !important;}
@media (max-width: 767.98px) {
  .top-head .top-head-link div span {
    padding: 20px 8px 20px 0px;
    line-height: 13px;
    float: left;
    font-size: 11px;
    font-weight: 500;
  }
  .category-menu {
    overflow: hidden;
  }
}
@media (max-width: 575.98px) {
  .row,
  .category-menu {
    margin-right: 0;
    margin-left: 0;
  }
  .sale-items .items {
    width: calc(100%);
  }
  .add-to-cart {
    position: absolute;
    right: 0;
  }
  .remove-from-cart{
    position: absolute;
    right: 15px;
    bottom: 60px;
    width: 55px;
    height: 55px;
  }
  .remove-from-cart i{
    line-height: 55px;
    width: 55px;
    height: 55px;
  }
  .sale-items-support textarea{
    width: 100%;
    float: left;
    clear: both;
    margin-top: 5px;
  }
  .sale-items-support{
    height: unset;
  }
  .sale-items-support input{
    width: 65px;
  }
}
@media (max-width: 364.98px) {
  .top-head .top-head-link div i {
    display: none;
  }
  .top-head .top-head-link div span {
    padding: 20px 10px 20px 10px;
  }
  .top-head .top-head-link div {
    border-radius: 8px;
  }
}
.error404{
  width: 90%;
  height: 100vh;
  display: block;
  margin: auto;
  padding: 5% 0;
  object-fit: contain;
}
